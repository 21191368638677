var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-right rounded-3 dashboard-card"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}}),(this.$store.state.loader)?_c('div',[_c('loader',{attrs:{"object":"#f74b3d","color1":"#ffffff","color2":"#17fd3d","size":"5","speed":"2","bg":"#343a40","objectbg":"#999793","opacity":"80","disableScrolling":"false","name":"spinning"}})],1):_c('div',{staticClass:"content-body",staticStyle:{"min-height":"calc(100vh - 135px)"},attrs:{"id":"fullHeight"}},[_c('h2',{staticClass:"title2"},[_vm._v("Dashboard")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 mb-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-start"},[_c('h2',{staticClass:"card-title"},[_vm._v("Attendance (Current Month)")]),_c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Late Arrivals")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'filter-myattendance',
                            params: { filterString: 'late-arrival' },
                          }}},[_vm._v(_vm._s(this.personalDashboardData.la != null ? this.personalDashboardData.la : ""))])],1)]),_c('tr',[_c('td',[_vm._v("Early Departures")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'filter-myattendance',
                            params: { filterString: 'early-departures' },
                          }}},[_vm._v(_vm._s(this.personalDashboardData.ed))])],1)])])])]),_vm._m(0)])])])]),_c('div',{staticClass:"col-lg-3 mb-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-start"},[_c('h2',{staticClass:"card-title"},[_vm._v("Present Schedule")]),_c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Time")]),(this.personalDashboardData.cs)?_c('td',[_vm._v(" "+_vm._s(this.personalDashboardData.cs != 1 && this.personalDashboardData.cs != null ? this.personalDashboardData.cs.intime : "")+" "+_vm._s(this.personalDashboardData.cs.outtime ? " - " + this.personalDashboardData.cs.outtime : "")+" ")]):_vm._e()]),_c('tr',[_c('td',[_vm._v("Rest Days")]),(this.personalDashboardData.cs)?_c('td',[_vm._v(" "+_vm._s(this.personalDashboardData.cs != 1 && this.personalDashboardData.cs != null ? this.personalDashboardData.cs.restday : "")+" ")]):_vm._e()])])])]),_vm._m(1)])])])]),_c('div',{staticClass:"col-lg-3 mb-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-start"},[_c('h2',{staticClass:"card-title"},[_vm._v("Leave of Absence")]),_c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Approved")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'my-filtered-leaves',
                            params: { filterString: 'approved' },
                          }}},[_vm._v(_vm._s(this.personalDashboardData.al))])],1)]),_c('tr',[_c('td',[_vm._v("Pending")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'my-filtered-leaves',
                            params: { filterString: 'pending' },
                          }}},[_vm._v(_vm._s(this.personalDashboardData.pl))])],1)]),_c('tr',[_c('td',[_vm._v("Rejected")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'my-filtered-leaves',
                            params: { filterString: 'rejected' },
                          }}},[_vm._v(_vm._s(this.personalDashboardData.rl))])],1)])])])]),_vm._m(2)])])])]),_c('div',{staticClass:"col-lg-3 mb-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-start"},[_c('h2',{staticClass:"card-title"},[_vm._v("Leave Status(Current Month)")]),_c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Leave Credits")]),_c('td',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v(_vm._s(this.personalDashboardData.lc))])])])])])]),_vm._m(3)])])])])]),_c('div',{staticClass:"t-layout"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"icon-calendar"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"icon-alarm"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"icon-exit"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"icon-exit"})])}]

export { render, staticRenderFns }