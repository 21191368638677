<template>
  <div class="content-right rounded-3 dashboard-card">
    <nav aria-label="breadcrumb">
      <!-- <ol class="breadcrumb">
        <li class="breadcrumb-item active">Dashboard</li>
      </ol> -->
    </nav>
    <div v-if="this.$store.state.loader">
      <loader
        object="#f74b3d"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        disableScrolling="false"
        name="spinning"
      ></loader>
    </div>
    <div
      v-else
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <h2 class="title2">Dashboard</h2>
      <div class="row">
        <div class="col-lg-3 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Attendance (Current Month)</h2>

                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>Late Arrivals</td>

                        <td>
                          <router-link
                            :to="{
                              name: 'filter-myattendance',
                              params: { filterString: 'late-arrival' },
                            }"
                            >{{
                              this.personalDashboardData.la != null
                                ? this.personalDashboardData.la
                                : ""
                            }}</router-link
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Early Departures</td>

                        <td>
                          <router-link
                            :to="{
                              name: 'filter-myattendance',
                              params: { filterString: 'early-departures' },
                            }"
                            >{{ this.personalDashboardData.ed }}</router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Present Schedule</h2>
                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>Time</td>
                        <td v-if="this.personalDashboardData.cs">
                          {{
                            this.personalDashboardData.cs != 1 &&
                            this.personalDashboardData.cs != null
                              ? this.personalDashboardData.cs.intime
                              : ""
                          }}
                          {{
                            this.personalDashboardData.cs.outtime
                              ? " - " + this.personalDashboardData.cs.outtime
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Rest Days</td>
                        <td v-if="this.personalDashboardData.cs">
                          {{
                            this.personalDashboardData.cs != 1 &&
                            this.personalDashboardData.cs != null
                              ? this.personalDashboardData.cs.restday
                              : ""
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-alarm"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Leave of Absence</h2>
                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>Approved</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'my-filtered-leaves',
                              params: { filterString: 'approved' },
                            }"
                            >{{ this.personalDashboardData.al }}</router-link
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Pending</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'my-filtered-leaves',
                              params: { filterString: 'pending' },
                            }"
                            >{{ this.personalDashboardData.pl }}</router-link
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Rejected</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'my-filtered-leaves',
                              params: { filterString: 'rejected' },
                            }"
                            >{{ this.personalDashboardData.rl }}</router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-exit"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Leave Status(Current Month)</h2>
                  <table style="width: 100%">
                    <tbody>
                      <!-- <tr>
                          <td>Total Paid Leaves </td>
                          <td><a href="javascript:void(0)">{{ this.personalDashboardData.tpl  }}</a></td>
                        </tr> -->
                      <!-- <tr>
                          <td>Leaves Taken</td>
                          <td><a href="javascript:void(0)">{{ this.personalDashboardData.tlt  }}</a></td>
                        </tr> -->
                      <tr>
                        <td>Leave Credits</td>
                        <td>
                          <a href="javascript:void(0)">{{
                            this.personalDashboardData.lc
                          }}</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-exit"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="t-layout">
        <!-- <div class="row">
          <div class="col-lg-6 mb-3">
            <div class="card">
              <div class="card-header">
                  <h5>Recent Attendances</h5>
              </div>
              <div class="card-body">
                <b-table
                  class="table table-custom"
                  :items="personalDashboardData.a"
                  :fields="recentattendancefields"
                  show-empty
                >
                  <template v-slot:cell(date)="data">
                      {{ data.item.date | formatDate}}
                  </template>
                  <template v-slot:cell(time)="data">
                    {{ data.item.timein != '' && (data.item.timeout == '' || data.item.timeout == null)  ? (formatTime(data.item.timein)) : (formatTime(data.item.timeout)) }}
                  </template>
                  <template v-slot:cell(description)="data">
                    {{ data.item.timein != '' && (data.item.timeout == '' || data.item.timeout == null) ? 'TimeIn' : 'TimeOut' }}
                  </template>
                  <template #empty="">
                      No Recent Attendances Found!
                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mb-3">
              <div class="card">
              <div class="card-header">
                  <h5>Previous Schedules</h5>
              </div>
              <div class="card-body">
                <b-table
                  class="table table-custom"
                  :items="personalDashboardData.ps"
                  :fields="previousSchedulefields"
                  show-empty
                >
                  <template v-slot:cell(time)="data">
                    {{ data.item.intime != null && data.item.intime != null ? (data.item.intime ) +'-'+ (data.item.intime ) : (data.item.intime ) +'-'+ (data.item.outtime ) }}
                  </template>
                  <template v-slot:cell(date)="data">
                    {{ (data.item.datefrom | formatDate) +'-'+ (data.item.datefrom | formatDate) }}
                  </template>
                  <template #empty="">
                      No Previous Schedules Found!
                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mb-3">
              <div class="card">
              <div class="card-header">
                  <h5>Recent Leaves of Absence</h5>
              </div>
              <div class="card-body">
                <b-table
                  class="table table-custom"
                  :items="personalDashboardData.ald"
                  :fields="recentLeavesfields"
                  show-empty
                >
                  <template v-slot:cell(name)="data">
                    {{ data.item }}
                  </template>
                  <template v-slot:cell(date)="data">
                    {{ data.item.leavefrom | formatDate}} {{ data.item.leaveto | formatDate}}
                  </template>
                  <template #empty="">
                      No Recent Leaves Found!
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      recentattendancefields: [
        {
          key: "date",
          tdAttr: { "custom-label": "Date:" },
        },
        {
          key: "time",
          tdAttr: { "custom-label": "Time:" },
        },
        {
          key: "description",
          tdAttr: { "custom-label": "Description:" },
        },
      ],
      previousSchedulefields: [
        {
          key: "time",
          tdAttr: { "custom-label": "Time:" },
        },
        {
          key: "date",
          label: "From Date / Untill",
          tdAttr: { "custom-label": "Date:" },
        },
      ],
      recentLeavesfields: [
        {
          key: "description",
          tdAttr: { "custom-label": "Description:" },
        },
        {
          key: "date",
          tdAttr: { "custom-label": "Date:" },
        },
      ],
    };
  },
  computed: {
    personalDashboardData() {
      return this.$store.state.personalDashboardData;
    },
  },
  beforeCreate() {
    this.$store.dispatch("loadRoleData");
  },
  created() {
    var token = localStorage.getItem("token");
    if (token == "" || token == null) {
      window.location.href = "/login";
    }
    if (this.$store.state.roleData == "") {
      window.reload();
    } else if (this.$store.state.roleData == 0) {
      window.location.href = "/login";
    }
  },
  mounted() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadPersonalDashboard");
    this.$store.dispatch("loadMyLeaves");
    this.$store.dispatch("loadMyattendances");
    this.$store.dispatch("loadPermissions");
  },
  methods: {
    formatTime(value) {
      return moment(String(value)).format("h:mm A");
    },
  },
};
</script>
